<template>
  <div class="progress-inner" :class="className">
    <div class="d-flex justify-space-between my-3">
      <h1 class=" progress-inner__title mr-3">{{ title }}</h1>
      <span class="progress-inner__subtitle d-md-none d-block mx-2">{{ subtitle }}</span>
    </div>
    <p v-html="content" class="pt-4 mb-0 progress-inner__content">
    </p>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    subtitle: String,
    content: String,
    active: Boolean,
  },
  computed: {
    className() {
      if (this.active) return "active";
      return null;
    },
  },
};
</script>